<!-- <div @mousewheel="handleScroll"> -->
<template>
  <div style="position: relative; min-width: 1600px; overflow: hidden">
    <div
      class="barnnerX"
      :style="{
        background: !isMobile
          ? 'url(' +
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/23e31bd101194e65b778d0b4515d3f42.jpg' +
            imgSuffix +
            ')'
          : 'none',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 90%',
      }"
    >
      <!-- backgroundPositionY: 'bottom', -->
      <div style="padding-top: 100px">
        <el-image
          :src="require('../../../static/newImage/露沟.jpg')"
          style="width: 690px"
        ></el-image>
      </div>
      <div style="margin-top: 60px">
        <el-image
          :src="require('../../../static/newImage/contact2.png')"
          style="width: 1540px"
        ></el-image>
      </div>
      <div
        style="
          width: 1540px;
          font-size: 26px;
          color: #fff8d6;
          line-height: 60px;
          text-align: justify;
          margin: 50px auto;
        "
      >
        浙江中峪控股集团有限公司
        是一家涉足商贸、农业、道地药材与新能源开发、数交所与大宗日用品交易市场、职业培训学校、中医大健康、医疗、生物制药、网络科技应用、养老产业、金融与创投等十大闭环内循环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营，服务于各生产企业的同时，为市场培育孵化更多优质的人才。紧跟国家战略部署，以乡村振兴为战略目标；以守护道地药材和传承弘扬发展中医文化为使命，把中医精髓代代相传并发扬光大，普惠大众；以振兴地区职业技能，培育多元化技术人才，助推就业与创业问题；为国家大健康战略规划真正落地生根普及，为提升老年人生活品质等民生领域及国家双碳战略和绿色生态发展，贡献自己的力量。
      </div>
      <div class="officialVideo" @click="showVideoFun">
        <el-image
          style="width: 100%"
          :src="
            'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-28/2cea01bc89174a839f00f4ebcf469264.png' +
            imgSuffix
          "
        ></el-image>
        <div class="play_video"></div>
      </div>
      <div>
        <el-image
          :src="require('../../../static/newImage/contact8.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact10.png')"
          style="width: 266px"
          width=""
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact9.png')"
          style="width: 50px"
        ></el-image>
      </div>
      <div
        style="
          margin: 40px auto;
          width: 1540px;
          font-size: 26px;
          color: #572424;
          line-height: 60px;
          text-align: justify;
          margin-bottom: 0;
          padding-bottom: 75px;
        "
      >
        极具前瞻性、战略性、智能化，在线化、网络化、去中心化、数据科技技术化。结合国家宏观政策和微观民生需求，做一个万物互联的产业链，实现线上线下联动运营，打造天地人网的产业格局，同时要实现数据智能加商业场景无缝融合的企业产品效应。借助麒典信息所具备的技术创新、数字数据优势，为闭环产业链内提供了驱动力、安全性，重构了整个商业逻辑的诞生的运营规律，使消费群体实现了整体的改变，同时商家拓客、锁客的模式进行了转型升级改变。数据智能私域化，实现精准广告、精准营销、精准零售、精准品宣。通过才、材、方、科技产品、文化的五位一体的产业融合，实现未来医养（银发）产业的推进和实施。
      </div>
      <!-- <div style="margin-top: 150px">
        <el-image src="../../../static/newImage/contact8.png" width="50px"></el-image>
        <el-image src="../../../static/newImage/contact4.png" width="266px"></el-image>
        <el-image src="../../../static/newImage/contact9.png" width="50px"></el-image>
      </div> -->
      <!-- 地图 -->
      <!-- <div
        style="
          width: 1540px;
          margin: 0 auto;
          padding-bottom: 50px;
          position: relative;
        "
        class="contact_map"
      >
        <el-image src="../../../static/newImage/contact24.png" width="1575px"></el-image>
        麒典 https://zoneyu.com.cn/
        <el-image
          src="../../../static/newImage/contact23.png"
          width="274px"
          style="position: absolute; top: 880px; left: 1180px"
          @click="toOther('https://zoneyu.com.cn')"
       ></el-image>
        数交 https://sj.zoneyu.net/
        <el-image
          src="../../../static/newImage/contact22.png"
          width="274px"
          style="position: absolute; top: 700px; left: 750px"
          @click="toOther('https://sj.zoneyu.net')"
       ></el-image>
        农业 https://www.zoneyu.cc/#/
        <el-image
          src="../../../static/newImage/contact21.png"
          width="274px"
          style="position: absolute; top: 830px; left: 1200px"
          @click="toOther('https://www.zoneyu.cc/#/')"
       ></el-image>
        集团 https://www.zoneyu.cn/
        <el-image
          src="../../../static/newImage/contact20.png"
          width="274px"
          style="position: absolute; top: 780px; left: 1180px"
          @click="toOther('https://www.zoneyu.cn')"
       ></el-image>
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
      </div> -->

      <el-image
        v-if="isMobile"
        :src="require('../../../static/newImage/bg_99.jpg')"
        class="testOne"
        style="z-index: -2"
      ></el-image>
    </div>
    <div
      class="contact_2"
      :style="{
        background: isMobile
          ? 'url(' + require('../../../static/newImage/contact7.jpg') + ')'
          : 'none',

        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <!-- backgroundPosition: '50% -15%', -->
      <el-image
        v-if="isMobile"
        :src="require('../../../static/newImage/contact99.jpg')"
        class="test"
      ></el-image>
      <div>
        <el-image
          :src="require('../../../static/newImage/contact8.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact12.png')"
          style="width: 266px"
          width="266px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact9.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
      </div>
      <!-- <div
        style="
          display: flex;
          width: 1540px;
          margin: 0 auto;
          margin-top: 50px;
          flex-wrap: wrap;
          justify-content: space-between;
        "
      >
        <el-image
          src="../../../static/newImage/contact13.png"
          width="290px"
          style="margin: 20px"
       ></el-image>
        <el-image
          src="../../../static/newImage/contact13.png"
          width="290px"
          style="margin: 20px"
       ></el-image>
        <el-image
          src="../../../static/newImage/contact13.png"
          width="290px"
          style="margin: 20px"
       ></el-image>
        <el-image
          src="../../../static/newImage/contact13.png"
          width="290px"
          style="margin: 20px"
       ></el-image>
      </div> -->
      <div class="grid-container">
        <div v-for="(item, index) in items" :key="index" class="grid-item">
          <el-image
            :src="item"
            alt=""
            srcset=""
            style="width: 236px; height: 165px"
          ></el-image>
        </div>
      </div>
      <div style="margin: 90px 0 0">
        <el-image
          :src="require('../../../static/newImage/contact8.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact5.png')"
          width="266px"
          style="width: 266px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact9.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
      </div>
      <div
        style="
          display: flex;
          width: 1540px;
          margin: 0 auto;
          margin-top: 50px;
          justify-content: space-between;
        "
      >
        <!-- flex-wrap: wrap; -->
        <el-image
          :src="require('../../../static/newImage/contact14.png')"
          style="width: 300px"
          width="300px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact15.png')"
          style="width: 300px"
          width="300px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact16.png')"
          style="width: 300px"
          width="300px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact17.png')"
          style="width: 300px"
          width="300px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact18.png')"
          style="width: 300px"
          width="300px"
        ></el-image>
      </div>
      <div style="margin: 90px 0 0">
        <el-image
          :src="require('../../../static/newImage/contact8.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact6.png')"
          style="width: 266px"
          width="266px"
        ></el-image>
        <el-image
          :src="require('../../../static/newImage/contact9.png')"
          style="width: 50px"
          width="50px"
        ></el-image>
      </div>
      <div style="display: flex; width: 1540px; margin: 20px auto">
        <div class="contact_address">
          <div class="threeBox">
            <el-image
              style="width: 40px; height: 35px"
              :src="require('@/assets/image/icon/咨询-投诉备份 2.png')"
            ></el-image>
            <div class="type">&nbsp;产品咨询</div>
            <div class="meCenter">
              如果您对我们的产品感兴趣，想深入了解、体验产品，请联系我们的产品咨询团队：
            </div>
            <div class="phone">咨询电话：400 1853 168</div>
          </div>
          <div class="threeBox" style="margin: 0 20px">
            <el-image
              style="width: 30px; height: 25px"
              :src="require('@/assets/image/icon/咨询-投诉备份 2.png')"
            ></el-image>
            <div class="type">&nbsp;售后咨询</div>
            <div class="meCenter">
              如果您在使用过程中遇到任何问题，我们的专业团队都会及时为您提供帮助，请联系：
            </div>
            <div class="phone">咨询电话：400 1853 168</div>
          </div>
          <div class="threeBox">
            <el-image
              style="width: 30px; height: 25px"
              :src="require('@/assets/image/icon/咨询-投诉备份 2.png')"
            ></el-image>
            <div class="type">&nbsp;人才引进</div>
            <div class="meCenter">
              发挥潜能，施展才华，创造成功。想深入了解，请联系我们：
            </div>
            <div class="phone">咨询电话：400 1853 168</div>
          </div>
        </div>
      </div>
      <!-- display: flex;  -->
      <div style="width: 1540px; margin: 20px auto">
        <el-image
          :src="require('../../../static/newImage/contact19.png')"
          width="100%"
          style="margin-bottom: 100px; width: 100%"
        ></el-image>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="showVideo"
      :show-close="false"
      width="70vw"
      top="5vh"
      :append-to-body="true"
      :before-close="
        () => {
          showVideo = false;
          this.$nextTick(() => {
            this.$refs['myvideo'].pause();
          });
        }
      "
    >
      <video ref="myvideo" controls preload="auto" width="100%">
        <source src="@/assets/video_demo.mp4" type="video/mp4" />
      </video>
      <i
        class="el-icon-close"
        style="
          position: absolute;
          top: 40px;
          right: 2px;
          font-size: 22px;
          color: #fff;
          background-color: #000;
          border-radius: 50%;
          padding: 10px;
          font-weight: bold;
          cursor: pointer;
        "
        @click="hideVideo"
      ></i>
    </el-dialog>
    <div class="video_show" v-show="showVideo1" @click="cancelVideo">
      <video controls preload="auto" width="100%" ref="myvideo1">
        <source src="@/assets/video_demo.mp4" type="video/mp4" />
      </video>
    </div>

    <!-- style="width: 50px" -->
    <!-- width="50px" -->
  </div>
</template>

<script>
import store from "@/store/store.js";
import { getAccessToken, getSignature } from "@/api/index";
export default {
  name: "contact_us",

  data() {
    return {
      body_id: 1,
      right_year: 2023,
      show: false,
      show_year: true,
      site_id: 1,
      roll: 0,
      scrollTop: 0,
      scroll: false,
      center: [120.503875, 30.091548],
      clickId: 1,
      list: [
        {
          id: 1,
          title: "企业介绍",
          content:
            "中峪日用品交易市场有限公司简称《中峪交易市场》。中峪交易市场于2021年2月正式批复成立的日用品交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激日用品市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。现在中峪交易市场拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。",
          image: "@/assets/image/2-banner/编组 13.png",
        },
        {
          id: 2,
          title: "企业文化",
          content:
            "中峪日用品交易市场有限公司简称《中峪交易市场》。中峪交易市场于2021年2月正式批复成立的日用品交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激日用品市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。现在中峪交易市场拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。",
        },
        {
          id: 5,
          title: "联系我们",
          content:
            "中峪日用品交易市场有限公司简称《中峪交易市场》。中峪交易市场于2021年2月正式批复成立的日用品交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激日用品市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。现在中峪交易市场拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。",
        },
      ],
      certificate: [
        {
          id: 1,
          text: "高新技术企业证书",
        },
        {
          id: 2,
          text: "高新技术企业证书",
        },
        {
          id: 3,
          text: "高新技术企业证书",
        },
      ],
      sitelist: [
        {
          id: 1,
          name: "浙江（总部）",
          site: "地址:浙江省绍兴市柯桥区金柯大道金昌国际金融中心(金柯大道东100米)2楼207号",
          telephone: "电话:400-185-3168",
        },
      ],
      isVisible: true,
      showVideo: false,
      showVideo1: false,
      haveTitle: true,
      imgIndex: 0,
      items: [
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/c8e904749b25493a8137f925a8fc4598.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/700fbf8371274a7d80cfb38527a5a639.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/deb4dcdc322940a1ba9e871b864ea4fb.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/1a515a6c84fc4a8dab58b100a7066122.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/a2fd72d11e9d4b709f9654e6b9fe31dc.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/702e2afa57a0435fb0da43483a8dc829.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/f2ea883e3e9c4f1b9fde92af386e3f71.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/32e5b09b49d24e5da0bca33c8b0f3b7e.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/e447ee85e4da4fd7bf56c2b8210f81ea.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/19d334b33af5473a9328f8945f0a83de.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/2ca0b5f84c6f4e01b235e742d319e437.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/c32a35d65c6f436681669016f86e0b30.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/4ae88baf372c48669af63fa9bb56cb2d.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/8bbe334c16104cd3917ef5f4b3e5a369.jpg",
        "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-14/b94c9a6ba9cc44b8a215d0d7c9ed3407.jpg",
      ],
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_500",
      imgSuffixTime: null,
      isMobile: false,
    };
  },

  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 500);
    if (
      document.getElementsByClassName("nav_1")[0] &&
      document.getElementsByClassName("nav_1")[0].parentElement.tagName ==
        "BODY"
    ) {
      document.getElementsByClassName("nav_1")[0].remove();
    }
    // this.$parent.nav_id = 5;
    localStorage.setItem("nav_id", this.$parent.nav_id);
    this.$parent.home_icon = false;
    if (this.$route.params.dingwei) {
      this.title_btn(5);
    }
    // 视频
    document.body.append(document.getElementsByClassName("video_show")[0]);
    // 滚动
    const reg =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    this.isMobile = reg.test(navigator.userAgent);
    console.log(
      !this.isMobile,
      this.isMobile,
      document.getElementsByClassName("testOne")[0]
    );
    if (!this.isMobile) {
      document.getElementsByClassName("testOne")[0].style =
        "transform: translateY(-34vh);z-index:-1;";
      console.log(document.getElementsByClassName("testOne")[0]);
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    } else if (/(Android)/i.test(navigator.userAgent)) {
    } else {
      window.addEventListener("scroll", this.changeTitle);
    }
    this.getWXInfo();
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime);
    // window.removeEventListener("scroll", this.changeTitle);
    // document.getElementsByClassName("nav_1")[0].remove();
    // if (document.getElementsByClassName("nav_1")[1]) {
    //   document.getElementsByClassName("nav_1")[1].remove();
    // }
  },
  methods: {
    // toOther(url) {
    //   window.open(url);
    // },
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.data.timestamp;
        const nonceStr = res.data.data.noncestr;
        const signature = res.data.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "中峪企业介绍", // 分享标题
            desc: "浙江中峪控股集团有限公司 是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才。同时紧跟国家战略部署，以乡村振兴为战略目标；以传承弘扬发展中医文化为使命，把中医精髓代代相传并发扬光大，普惠大众；以振兴地区职业技能，培育多元化技术人才，助推就业问题；为国家大健康战略规划真正落地生根普及，为提升老年人生活品质等民生领域，贡献自己的力量。", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/269d349bef54469fb60ca528f15eca00.jpg", // 分享图标
          };

          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    cancelVideo() {
      this.showVideo1 = false;
      this.$refs["myvideo1"].pause();
    },
    changeTitle() {
      // if (window.scrollY > 780) {
      //   if (this.haveTitle) {
      //     const html = this.$refs["title"];
      //     if (html) {
      //       document.body.append(html);
      //     }
      //     this.$refs["title"].style = "display: flex";
      //     document.getElementsByClassName("titleinside_child")[0].style =
      //       "opacity: 0";
      //     this.haveTitle = false;
      //   }
      // } else if (window.scrollY < 700) {
      //   if (!this.haveTitle) {
      //     document.getElementsByClassName("titleinside_child")[0].style =
      //       "opacity: 1";
      //     this.$refs["title"].style = "display: none";
      //     this.haveTitle = true;
      //   }
      // }
    },
    hideVideo() {
      this.showVideo = false;
      this.$nextTick(() => {
        this.$refs["myvideo"].pause();
      });
    },
    showVideoFun() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.showVideo1 = true;
        this.$nextTick(() => {
          this.$refs["myvideo1"].play();
        });
      } else if (/(Android)/i.test(navigator.userAgent)) {
        this.showVideo1 = true;
        this.$nextTick(() => {
          this.$refs["myvideo1"].play();
        });
      } else {
        this.showVideo = true;
        this.$nextTick(() => {
          this.$refs["myvideo"].play();
        });
      }
    },
    title_btn(v) {
      this.clickId = v;
      this.body_id = v;
      this.show = false;
      this.show_year = true;
      switch (this.body_id) {
        case 1:
          window.scrollTo(0, 760);
          break;
        case 2:
          window.scrollTo(0, 2200);
          break;
        case 5:
          window.scrollTo(0, 2920);
          break;
        default:
          break;
      }
    },
    right_year_btn(v) {
      this.right_year = v;
      //   console.log(v);
    },
    unfold() {
      this.show = !this.show;
      this.show_year = !this.show_year;
    },
    // 地图跳转
    siteClick(v) {
      this.site_id = v;
      //   console.log(this.site_id, "this.site_id");
      switch (this.site_id) {
        case 1:
          //   this.center = [120.503618, 30.091776];
          //   console.log(store.state.center);
          this.$store.commit("centerChange", this.site_id);
          this.$emit("event-from-parent", store.state.center);
          break;
        case 2:
          this.$store.commit("centerChange", this.site_id);
          this.$emit("event-from-parent", store.state.center);
          break;
        case 3:
          //   window.scrollTo(0, 1868.1);
          break;
        case 4:
          //   window.scrollTo(0, 2444.3);
          break;
        case 5:
          //   window.scrollTo(0, 3092.1);
          break;
        default:
          break;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.changeTitle);
    this.showVideo1 = false;
    this.$refs["myvideo1"].pause();
    document.getElementsByClassName("nav_1")[0].remove();
  },
};
</script>

<style scoped lang="scss">
.officialVideo {
  position: relative;
  width: 1300px;
  margin: 100px auto;
  border-radius: 20px;
  border: 2px solid #800000;
  height: 728px;
  position: relative;
  z-index: 99;
  margin-top: 50px;
  margin-bottom: 90px;
  overflow: hidden;
}
.officialVideo::after {
  cursor: pointer;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    top,
    rgba(146, 20, 18, 0.4),
    rgba(255, 0, 0, 0.1)
  );
}
.dot {
  position: absolute;
  z-index: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #b31f1f;
    z-index: 1;
  }
  &::before {
    animation: amplify1 2s infinite;
  }
}
@keyframes amplify1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.dot1 {
  left: 750px;
  top: 750px;
  background-color: #b31f1f;
  &::before {
    background-color: rgba(215, 138, 132, 1);
  }
}
.dot2 {
  left: 1180px;
  top: 850px;
  background-color: #b31f1f;
  &::before {
    background-color: rgba(215, 138, 132, 1);
  }
}
.contact_map {
  transform: translateY(-70px);
  img {
    cursor: pointer;
    z-index: 2;
  }
}
.contact_2 {
  background-image: url("../../../static/newImage/contact7.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  z-index: -1;
  position: relative;
  padding-top: 15px;
}
.contact_1 {
  padding-top: 650px;
  background-image: url("../../../static/newImage/contact3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  z-index: -1;
  position: relative;
}
.video_show::before {
  content: " ";
  width: 200vw;
  height: 200vh;
  background-color: #00000062;
}

.video_show {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  video {
    height: 100%;
    width: 100%;
  }
}

.box {
  height: 100%;

  .nav_ {
    height: 70px;
    display: flex;
    padding: 0 210px;
    font-size: 20px;
    line-height: 70px;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;

    .from_list {
      width: 245px;
      text-align: center;
      cursor: pointer;
      /* 更改鼠标样式为手 */
      position: relative;

      .click_sty {
        color: red;
      }
    }
  }
}

.play_video {
  position: absolute;
  cursor: pointer;
  z-index: 12;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  top: 350px;
  left: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: rgba(255, 255, 255, 0.3);
}

.play_video::after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 26px solid #ffffff;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  position: absolute;
  top: 18px;
  left: 28px;
}

::v-deep .el-dialog {
  background: transparent;
  box-shadow: none;
}

.barnnerX {
  // background: url();
  width: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  text-align: center;
  background-position-y: top;
}

.boundary {
  padding: 20px 0;
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  position: relative;
  /* background: red; */
}

.body_right {
  width: 100%;
  flex: 1;
  background: #fff;
  padding: 0 20px;
}

.body_title {
  padding: 0 10px;
  height: 60px;
  cursor: pointer;
  font-size: 14px;
  line-height: 60px;
  text-align: left;
}

.body_click {
  color: #000;
  font-size: 18px !important;
  position: relative;
  font-weight: bold;
}

.body_click::after {
  content: "";
  height: 55px;
  width: 4px;
  background-color: #e1251b;
  position: absolute;
  top: 0px;
  right: 0;
}

.imgs {
  width: 100%;
  height: 380px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.imgs_item {
  transition: width 0.2s ease-in-out;
  width: 180px;
  object-fit: cover;
  cursor: pointer;
  /* 添加过渡效果 */
}

.imgs_tips {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.imgs_tips_item {
  text-align: center;
  transition: width 0.2s ease-in-out;
  width: 180px;
  cursor: pointer;
  overflow: hidden;

  .imgs_tips_item_bg {
    background-color: rgba(225, 21, 21, 0.8);
    color: #fff;
    margin: 0 auto;
    width: max-content;
    padding: 0 10px;
    line-height: 34px;
    border-radius: 5px;
  }
}

// .imgs_item:hover,
// .imgs_tips_item:hover {
//   width: 452px;
// }

.active {
  width: 452px;
}

.right_list {
  margin-bottom: 48px;
  cursor: pointer;
  color: #181818;
  opacity: 0.45;
}

.right_sty {
  font-size: 20px;
  color: #000;
  opacity: 1;
}

.certificate_sty {
  display: flex;
  justify-content: space-around;
}

.contact_address {
  width: 100%;
  height: 240px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.threeBox {
  width: 100%;
  height: 75%;
  padding: 28px;
  background-color: #fcefe8;
  text-align: left;
}

.site {
  display: flex;
  margin-top: 40px;
  cursor: pointer;
  flex-wrap: wrap;
}

.siteName {
  flex: 0 0 calc(35% - 10px);
  margin-right: 100px;
}

.mapName {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.mapSite {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 8px;
}

.mapPhone {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 32px;
}

.site_sty {
  color: #e1251b;
}

.type {
  display: inline-block;
  position: relative;
  top: -8px;
  line-height: 33px;
  font-size: 24px;
  margin-bottom: 32px;
}

.meCenter {
  font-size: 19px;
  color: #181818;
  line-height: 30px;
  margin-bottom: 24px;
}

.phone {
  font-size: 16px;
  font-weight: 400;
  color: #181818;
  line-height: 22px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 一行显示5个 */
  gap: 20px 0;
  max-width: 1350px;
  padding-top: 50px;
  margin: 0 auto;
  justify-items: center;
  align-items: center;
}

.grid-item {
  width: 236px;
  height: 165px;
  text-align: center;
  border: 4px solid #d6c6c6;
  // padding: 0 100px;
}
.testOne {
  width: 100%;
  position: absolute;
  min-height: 2100px;
  top: 0;
  left: 0;
  z-index: -1;
}
.test {
  width: 100%;
  position: absolute;
  min-height: 2100px;
  top: 0;
  left: 0;
  z-index: -1;
  // z-index: -1;
}
</style>
